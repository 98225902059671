<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Actions
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          ></span
        >
      </div>
      
    </div>
    <form class="form">
      <div class="card-body">
        
	

		<div id="app" v-if="save_button">
			<div  v-for="(item, index) in actionData" :key="index">
			<el-checkbox v-model="actionData[index].refresh">Refresh</el-checkbox><br/>
		    <el-checkbox v-model="actionData[index].create">Create</el-checkbox><br/>
		    <el-checkbox v-model="actionData[index].export">Export selection</el-checkbox>
		  </div>
		<div class="repeater" v-if="repeaterData">
				

				
			</div>
			
	
              <input 
                class="form-control form-control-lg form-control-solid"
                ref="Actions"
                type="hidden"
				:value="JSON.stringify(repeaterData)"
              />
			  

			  
      </div>

      </div>
	  <div class="card-footer d-flex justify-content-end py-6 px-9">
	  <span :class="spinner"></span>
      <button v-if="save_button"
        type="button"
        class="btn btn-primary"
        @click="saveTrigger()"
        ref="kt_save_changes"
      >
      Save Changes
      </button>
    </div>
    </form>
  </div>
</template>

<script>
	import axios from 'axios'
	
    export default {
       data(){
           return {
			  repeaterData: null,
			  actionData: [{create: false, refresh: false, export: false}],
			  options_col_type: [
				  { value: null, text: '' },
				  { value: 'id', text: 'ID' },
				  { value: 'customValue', text: 'Custom value' },
				  { value: 'parentValue', text: 'Parent value' },
				],
			  options_col_operator: [
				  { value: null, text: '' },
				  { value: '=', text: 'Equal' },
				  { value: '!=', text: 'Not equal' },
				  { value: 'like', text: 'Contains' },
				  { value: 'not like', text: 'Does not contain' },
				],
			  options_col_field: [],
              spinner: '',
              save_button: true,
              uid: this.$route.params.uid,
			  ajaxData: {"component":{"actions":null}}
           }
       },
		methods:{
			async saveChanges () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.save_button = false
			  axios({
				method: 'put',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid,
				data: {
					actions: JSON.stringify(this.actionData)
				  }
			  }).then((response) => {
				this.ajaxData = response.data.result
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			async refreshTable () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.save_button = false
			  axios({
				method: 'get',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid
			  }).then((response) => {
			  
				const action_data = JSON.parse(response.data.result.item.actions);
				
				for (let i = 0; i < action_data.length; i++) {
					if(Object.prototype.hasOwnProperty.call(action_data[i],'create')){
						if(action_data[i].create == "true"){
							this.actionData[0].create = true;
						}
						if(action_data[i].create == true){
							this.actionData[0].create = true;
						}
					}
					if(Object.prototype.hasOwnProperty.call(action_data[i],'refresh')){
						if(action_data[i].refresh == "true"){
							this.actionData[0].refresh = true;
						}
						if(action_data[i].refresh == true){
							this.actionData[0].refresh = true;
						}
					} 
					if(Object.prototype.hasOwnProperty.call(action_data[i],'export')){
						if(action_data[i].export == "true"){
							this.actionData[0].export = true;
						}
						if(action_data[i].export == true){
							this.actionData[0].export = true;
						}
					} 
				} 
		
				
				this.ajaxData = response.data.result
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			saveTrigger() {
			  this.ajaxData.item.actions = this.$refs.Actions.value
			  this.saveChanges()
			},
			positionUp: function(val){
				if( val == 0){
					return false;
				}
				const newArray = this.repeaterData;
				const new_index = (val - 1);
				const current_index = val;
			
				if (new_index >= newArray.length) {
					let k = new_index - newArray.length + 1;
					while (k--) {
						newArray.push(undefined);
					}
				}
				newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
			},
			positionDown: function(val){
				if( val == (this.repeaterData.length - 1)){
					return false;
				}
				// alert(this.repeaterData.length+' - '+val)
				const newArray = this.repeaterData;
				const new_index = (val + 1);
				const current_index = val;
			
				if (new_index >= newArray.length) {
					let k = new_index - newArray.length + 1;
					while (k--) {
						newArray.push(undefined);
					}
				}
				newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
			},
			generate: function(){
				this.repeaterData.push({type:'', field:'', title: ''})
			},
			remove: function(e, index){
			// alert(index);
				this.repeaterData.splice(index, 1);
	
			},
			change_option(a, b, c){
				if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
					// this.positionDown(0)
				} else{
					this.repeaterData[a].options = {}
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
					// this.positionDown(0)
				}
			},
			options_continue(a){
				if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					if(this.repeaterData[a].options['selection'] == null){
						this.repeaterData[a].options['link'] = ''
						this.repeaterData[a].options['field'] = ''
						this.positionUp(0)
					}
				}
			},
			select_option(a, b, c){
				if(!Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
					this.repeaterData[a].options = {}
					this.repeaterData[a].options[c] = b
					this.positionUp(0)
				}
			},
			checkOptions(obj, check){
				if(Object.prototype.hasOwnProperty.call(obj, 'options')){
					if(Object.prototype.hasOwnProperty.call(obj.options, check)){
						return obj.options[check];
					}
				}
			}
		},
		watch: {
			ajaxData: function (val) {
				this.spinner = 'spinner spinner-sm spinner-success spinner-right'
				this.save_button = false
				axios({
					method: 'options',
					url: 'https://keetels-api.eliteswitch.com/api/'+val.item.endpoint
				  }).then((response) => {
					if(response.data.result){
					 for (const field in response.data.result.data.fields) {
						this.options_col_field.push({value: field, text: field})
					 }
					}
					
					 this.spinner = ''
					 // if(response.data.result.length > 0){
						this.save_button = true
					 // }
					 

				  }, (error) => {
					console.log(error)
				  })
			
			},
		  },
		created: function(){
			this.repeaterData = [];
		},
		mounted () {
			this.refreshTable()
		  },
    }
	
</script>
